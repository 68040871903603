<template>
  <div>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title class="error darken-1 white--text px-5 pb-3">
          <span class="title font-weight-bold"> {{ $t('deleteConfirm') }} </span>
          <v-spacer></v-spacer>
          <v-icon dark> mdi-alert </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          {{ $t('areYouSure') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" small :loading="loading" @click="confirmDelete" class="text-capitalize"> {{ $t('yes') }} </v-btn>
          <v-btn color="primary" small @click="showDialog = false" class="text-capitalize"> {{ $t('no') }} </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- PERMANENT DELETE WARNING -->
    <v-dialog v-model="permanentDeleteDialog" width="500" persistent>
      <v-card>
        <v-card-title class="error darken-1 white--text px-5 pb-3">
          <span class="title font-weight-bold"> {{ $t('deleteConfirm') }} </span>
          <v-spacer></v-spacer>
          <v-icon dark> mdi-alert </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-5 py-4">
          <span class="subtitle-1 d-flex my-1" v-if="!permanentDeletePayload.singleDelete"> {{ `${$t('delete')} ${permanentDeletePayload.ids ? permanentDeletePayload.ids.length : ''} ${$t('records')} ?` }} </span>
          <code class="subtitle-2 red--text"> {{ $t('permanentDeleteMsg') }} </code>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-3 white--text" class="px-3" @click="deleteRecordsPermanently" :loading="deletePermanentLoader"> {{ $t('deletePermanently') }} </v-btn>
          <v-btn color="primary" class="mr-3 px-3" @click="permanentDeleteDialog = false"> {{ $t('cancel') }} </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- SET LANDING PAGE-->
    <v-dialog v-model="setLandingPage" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('setasLandingPage') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <h4> {{ $t('areYouSureYouWantToSetthisasLandingPage') }} </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" id="savelink" :loading="linkLoading" @click="saveLandingPageHandler" class="mr-3">{{ $t('save') }}</v-btn>
            <v-btn color="error" id="linkclose" @click="closeLandingPageDialog()">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CUSTOM DIALOG -->
    <v-dialog v-model="customDialogObj.show" :width="customDialogObj.width || 500"
      :persistent="customDialogObj.persistent === false ? false : true">
      <v-card :loading="customDialogObj.loading">
        <v-card-title :class="['pt-3 pb-2 px-4 primary white--text', (customDialogObj.title && customDialogObj.title.class) ? (customDialogObj.title.class({ dialog: customDialogObj })) : '']">
          <span class="subtitle-1 font-weight-bold"> {{ (customDialogObj.title && customDialogObj.title.label) ? customDialogObj.title.label : '' }} </span>
          <v-spacer></v-spacer>
          <v-icon dark v-if="customDialogObj.title && customDialogObj.title.icon"> {{ customDialogObj.title.icon }} </v-icon>
        </v-card-title>
        <v-card-text class="py-3 px-4 text-left">
          <span class="body-2 font-weight-medium"> {{ customDialogObj.body ? customDialogObj.body({ dialog: customDialogObj }) : '' }} </span>
        </v-card-text>
        <v-divider v-if="customDialogObj.actions && customDialogObj.actions.length"></v-divider>
        <v-card-actions v-if="customDialogObj.actions && customDialogObj.actions.length">
          <v-spacer></v-spacer>
          <template v-for="(btn, index) in customDialogObj.actions">
            <v-btn :key="index" v-if="btn.show ? btn.show({ dialog: customDialogObj, button: btn }) : true" :disabled="btn.disabled"
              :color="btn.label === 'No' && $vuetify.theme.dark ? 'white': btn.color({ dialog: customDialogObj, button: btn })" :loading="btn.loading" :outlined="btn.outlined"
              class="text-capitalize" @click="btn.click && btn.click({ dialog: customDialogObj, button: btn }); $forceUpdate()" small
            >
              {{ btn.label }}
            </v-btn>
          </template>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- BOTTOM NAVIGATION -->
    <v-bottom-navigation app dark :style="`background-color: ${$vuetify.theme.dark ? '#181818' : systemDetails.themecolor}`" background-color="small_device_custom_spacing" fixed>
      <!-- Quick add -->
       <v-menu offset-y top v-if="listOfQuickaddModules.length > 0">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" icon v-on="on" small style="color: #fff !important;">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card max-height="333">
          <v-list dense>
            <v-list-item class="px-3" v-for="(item, i) in listOfQuickaddModules" :key="i" id="moduleList" :to="`${item.to}/actions`">
              <v-list-item-icon class="mr-1">
                <v-icon size="19" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <!-- Menu items -->
      <template v-for="(menu, menuIndex) in menuLinks">
        <v-btn :key="menuIndex" v-if="menu.isVisible" icon :to="menu.to"
        class="bottom-navigation-button" style="color: #fff !important;">
          <v-icon dark> {{ menu.icon }} </v-icon>
        </v-btn>
      </template>
      <!-- User logout/lang change -->
      <v-btn icon small :color="systemDetails.themecolor" @click="$root.$emit('profileMenuBar')">
        <v-icon :color="systemDetails.textcolor"> mdi-account-settings </v-icon>
      </v-btn>
      <!-- <v-menu offset-y top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" small style="color: #fff !important;">
            <v-icon dark>mdi-account-settings</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item dense v-for="(item, index) in menuItems" :key="index" @click="menuActions(item.type, item.code)">
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
               <v-list-item-title>{{ item.type === 'logout' ||  item.type === 'switch' || item.type === "landingpage" || item.type === "whatsnew" ? $t(item.title) : item.title }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-bottom-navigation>
    <profile-menu-bar></profile-menu-bar>
    <!-- FILTERS MENU -->
    <filters-menu></filters-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      setLandingPage: false,
      linkLoading: false,
      landingPageObj: {},
      loading: false,
      menuLinks: [],
      menuItems: [{
        title: 'switchWorkspace',
        icon: 'mdi-account-switch',
        type: 'switch',
        value: ''
      }, {
        title: 'whatsnew',
        icon: 'mdi-new-box',
        type: 'whatsnew',
        value: ''
      }, {
        title: 'logout',
        icon: 'mdi-power',
        type: 'logout',
        value: ''
      }],
      // custom dialog
      customDialogObj: {
        close: () => { this.customDialogObj.show = false },
        loading: false
      },
      // delete permanent
      permanentDeleteDialog: false,
      deletePermanentLoader: false,
      permanentDeletePayload: {}
    }
  },
  components: {
    'profile-menu-bar': () => import('@/components/ProfileMenuBar.vue'),
    'filters-menu': () => import('@/components/CustomModules/Filters.vue')
  },
  computed: {
    ...mapGetters(['showDialog', 'userDetails', 'deletePayload', 'listOfModules', 'listOfQuickaddModules', 'systemDetails', 'getTimerSettings']),
    showDialog: {
      get () {
        return this.$store.state.common.showDialog
      },
      set (val) {
        if (!val) this.$store.commit('hideDialog')
      }
    },
    // menuItems () {
    //   let language
    //   if (this.$i18n.locale === 'en') {
    //     language = { title: 'norwegian', value: 'no', click: 'language' }
    //   } else if (this.$i18n.locale === 'no') language = { title: 'english', value: 'en', click: 'language' }
    //   const items = [language, { title: 'logout', click: 'logout' }]
    //   return items
    // },
    showBack () {
      return this.$route.path !== '/dashboard'
    }
  },
  created () {
    this.$store.dispatch('getModuleList')
  },
  beforeMount () {
    if (this.userDetails && this.userDetails._id) this.getAvailableLanguages()
    localStorage.setItem(`${process.env.VUE_APP_NAME}_Timer_enablestamptime`, JSON.stringify(this.getTimerSettings.enablestamptime))
  },
  mounted () {
    let menus = [{ icon: 'mdi-home', to: '/dashboard', isStatic: true, name: 'dashboard', isVisible: true }]
    if (this.userDetails.allowedfeatures && this.userDetails.allowedfeatures.length > 0) {
      if (this.userDetails.allowedfeatures.includes(this.TIMER)) {
        menus = [...menus, {
          icon: 'mdi-clock-outline',
          to: '/timer',
          isStatic: true,
          name: 'timer',
          isVisible: true
        }, {
          icon: 'mdi-timer',
          to: '/stemp',
          isStatic: true,
          name: 'stemp',
          isVisible: this.getTimerSettings.enablestamptime
        }]
      }
    }
    this.menuLinks = menus

    // custom dialog
    this.$root.$on('customDialog', (payload) => {
      this.customDialogObj = Object.assign({}, { ...this.customDialogObj, ...payload, show: true })
    })
    // delete handler
    this.$root.$on('delete', (payload) => {
      this.customDialogObj = {
        title: {
          label: this.$t('deleteConfirm'),
          icon: 'mdi-alert',
          class: () => 'error darken-1 white--text px-5 pb-3'
        },
        body: () => this.$t('areYouSure'),
        actions: [{
          label: this.$t('yes'),
          color: () => 'error',
          click: ({ dialog, button }) => {
            button.loading = true
            const { payload } = dialog
            const isMultiple = this.$formatter.isArray(payload._id)
            const url = isMultiple ? `${payload.module}/delete_multiple` : payload._id ? `${payload.module}/${payload._id}` : `${payload.module}`
            if (payload.isSoftDelete) {
              this.$api.execute('post', url, { ids: payload._id })
                .then(() => payload.onResolve && payload.onResolve())
                .catch((error) => this.$root.$emit('snackbar', { text: error, color: 'error' }))
                .finally(() => {
                  button.loading = false
                  dialog.close()
                })
            } else {
              this.$api.execute('delete', url, { data: { ids: payload._id } })
                .then(() => payload.onResolve && payload.onResolve())
                .catch((error) => this.$root.$emit('snackbar', { text: error, color: 'error' }))
                .finally(() => {
                  button.loading = false
                  dialog.close()
                })
            }
            // this.$api.execute('delete', url, { data: { ids: payload._id } })
            //   .then(() => payload.onResolve && payload.onResolve())
            //   .catch((error) => this.$root.$emit('snackbar', { text: error, color: 'error' }))
            //   .finally(() => {
            //     button.loading = false
            //     dialog.close()
            //   })
          }
        }, {
          label: this.$t('no'),
          color: () => 'primary',
          outlined: true,
          click: ({ dialog, button }) => {
            payload.onReject ? payload.onReject({ dialog, button }) : dialog.close()
          }
        }],
        ...this.customDialogObj,
        show: true,
        payload
      }
    })
    // permanent delete handler
    this.$root.$on('showPermanentDeleteDialog', (payload) => {
      this.permanentDeletePayload = payload
      this.permanentDeleteDialog = true
    })
    this.$root.$on('isloading', (data) => {
      this.loading = data
    })
  },
  methods: {
    deleteRecordsPermanently () {
      const { ids, modulename = this.$route.params.name, oldDeleteMethod, onResolve, url: urlto } = this.permanentDeletePayload

      const model = { data: { ids } }
      this.deletePermanentLoader = true

      const url = urlto || ((oldDeleteMethod)
        ? `${modulename}/delete_multiple`
        : `moduledata/${modulename}/delete_permanent_multiple`)

      this.$api.execute('delete', url, model)
        .then(async () => {
          await onResolve && onResolve()
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteSuccess' })
        })
        .catch(async (exception) => {
          await this.permanentDeletePayload.onReject && this.permanentDeletePayload.onReject(exception)
        })
        .finally(async () => {
          await this.permanentDeletePayload.onComplete && this.permanentDeletePayload.onComplete()
          this.permanentDeleteDialog = false
          this.deletePermanentLoader = false
          setTimeout(() => {
            this.permanentDeletePayload = {}
          }, 10)
        })
    },
    showLandingPageDialog () {
      this.setLandingPage = true
    },
    saveLandingPageHandler () {
      var model = this.$formatter.cloneVariable(this.landingPageObj)
      model.Value = this.$route.path
      this.linkLoading = true
      this.$api.execute('post', 'users/set_landing_page', model).then(response => {
        if (response.data) { this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'saveSuccess' }) }
      }).finally(() => {
        this.linkLoading = false
        this.closeLandingPageDialog()
      })
    },
    closeLandingPageDialog () {
      this.setLandingPage = false
      this.landingPageObj = {}
    },
    menuActions (action, value) {
      switch (action) {
        case 'logout':
          this.$store.dispatch('logout')
          this.$router.push('/login')
          break
        case 'switch':
          this.$router.push({ path: '/tenantsavailable', query: { userswitchworkspace: 'true' } })
          break
        case 'landingpage':
          this.showLandingPageDialog()
          break
        case 'whatsnew':
          this.navigateToFeatureHandler()
          break
        default:
          this.changeLang(value)
          break
      }
    },
    changeLang (language) {
      this.$i18n.locale = language
      this.$cookie.set(process.env.VUE_APP_LOCALE, language)
      this.$router.push(`${this.$route.path}?lang=${language}`)
    },
    getAvailableLanguages () {
      this.$api.execute('get', `languages/get_by_domain?domain=${this.$store.state.auth.userDetails.domain}`).then(response => {
        response.data.forEach(element => {
          if (element.isactive) {
            this.menuItems.splice(0, 0, { title: element.name, code: element.code, click: element.code })
          }
        })
      })
    },
    confirmDelete () {
      this.loading = true
      const model = this.deletePayload
      if (model.isSingle) {
        this.$store.dispatch('deleteSingleRecordHandler', model)
          .then(() => {
            this.$eventBus.$emit('deleteSuccess', model.module)
          })
          .finally(() => {
            this.loading = false
          })
      } else if (model.isChecklist) {
        this.$root.$emit('deleteRecord', true)
      } else {
        this.$store.dispatch('deleteHandler', model)
          .then(() => {
            this.$eventBus.$emit('deleteSuccess', model.module)
            this.$eventBus.$emit('notes', false)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    navigateToFeatureHandler () {
      this.$router.push('/whatsnew').catch(() => {})
    }
  },
  beforeDestroy () {
    this.$root.$off('customDialog')
    this.$root.$off('delete')
    this.$root.$off('showPermanentDeleteDialog')
    this.$root.$off('isloading')
  }
}
</script>
<style scoped>
  @media screen and (max-width: 399px){
    .small_device_custom_spacing > a.v-btn, .small_device_custom_spacing > button.v-btn {
      min-width: 75px;
      font-size: 0.6rem;
    }
  }
  @media screen and (max-width: 379px){
    .small_device_custom_spacing > a.v-btn, .small_device_custom_spacing > button.v-btn {
      min-width: 70px;
      font-size: 0.6rem;
    }
  }
  @media screen and (max-width: 359px){
    .small_device_custom_spacing > a.v-btn, .small_device_custom_spacing > button.v-btn {
      min-width: 65px;
      font-size: 0.6rem;
    }
  }
  @media screen and (max-width: 329px){
    .small_device_custom_spacing > a.v-btn, .small_device_custom_spacing > button.v-btn {
      min-width: 60px;
      font-size: 0.6rem;
    }
  }
  .bottom-navigation-button.v-btn--active {
    border-bottom: 2px solid #fff !important;
  }
</style>
